import { switchProdAffBlack } from 'api/product/detail';
import AddAdjustProdRate from '../../product/components/AddAdjustProdRate.vue';
import BlackForSource from '../../product/components/BlackForSource.vue';
import BlackForAffiliate from '../../product/components/BlackForAffiliate.vue';
export default {
  components: {
    AddAdjustProdRate,
    BlackForSource,
    BlackForAffiliate,
  },
  data() {
    return {
      activeName: 'first',
      prodId: this.$route.query.prodId,
      packageName: this.$route.query.packageName,
      status: this.$route.query.affBlacklistSwitch + '',
    };
  },
  methods: {
    statusChange(val) {
      switchProdAffBlack(this.prodId, val).then((response) => {
        if (response.code == 200) {
          this.$message({
            message: 'Update Success',
            type: 'success',
          });
          this.status = val;
        } else {
          this.status = !val;
          this.$message.error('Update Error:' + response.message);
        }
      });
    },
  },
};
