<template>
  <div class="product_detail">
    <div class="mainBox">
      <div class="main-Title bgff mb10">
        <h2>
          Product Detail:
          <span>{{ packageName }}</span>
        </h2>
      </div>
      <el-tabs v-model="activeName" type="border-card">
        <!-- 开关针对包名拉黑所有渠道，不让所有渠道跑该包名的offer -->
        <el-tab-pane label="Black For All Affiliate" name="first">
          <div class="pl10">
            <span class="font-16 mr10">Black For All Affiliate</span>
            <el-switch
              v-model="status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="true"
              inactive-value="false"
              @change="statusChange"
            ></el-switch>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Add Adjust Prod Rate" name="second">
          <add-adjust-prod-rate :packageName="packageName" />
        </el-tab-pane>
        <!--Setting-->
        <el-tab-pane label="Black For Source" name="third">
          <black-for-source :packageName="packageName" />
        </el-tab-pane>
        <el-tab-pane label="Black For Affiliate" name="fourth">
          <black-for-affiliate :packageName="packageName" :prodId="prodId" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import ProductDetailCon from '../controllers/product/ProductDetail';

  export default {
    name: 'ProductDetail',
    ...ProductDetailCon,
  };
</script>
